import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { LoaderSizeProps } from 'react-spinners/interfaces'
import { ThemeContext } from 'styled-components'

export type Props = LoaderSizeProps

const Loader = (props: Props) => {
	const { colors } = React.useContext(ThemeContext)

	return <ClipLoader color={colors.grayDark} {...props} />
}

export default Loader
